import { EditorScriptFlowAPI } from '../constants/types';

let conductedExperiments: EditorScriptFlowAPI['experiments'];

export const initExperiments = (flowApi: EditorScriptFlowAPI) => {
  conductedExperiments = flowApi.experiments;
};

export const isADIHorizontalEnabled = () =>
  conductedExperiments.enabled('specs.membersArea.ADIHorizontalLayout');
